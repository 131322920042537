import { InertiaApp } from '@inertiajs/inertia-react'
import React from 'react'
import { render } from 'react-dom'
import axios from 'axios'
import '../stylesheets/application.scss'

axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

declare const app: { dataset: { page: string } }

document.addEventListener('DOMContentLoaded', () => {
  render(
    <InertiaApp
      initialPage={JSON.parse(app.dataset.page)}
      resolveComponent={name =>
        import(`../${name}`).then(module => module.default)
      }
    />,
    document.querySelector('#app-container')
  )
})
